<template>
  <div>
    <v-tabs
      v-model="tab"
      fixed-tabs
      color="white"
      background-color="transparent"
    >
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <component v-bind:is="item.content"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>
.v-tab {
  color: rgb(59, 63, 55) !important;
  background-color: rgb(120, 128, 111);
}
.v-tab.v-tab--active {
  color: white !important;
  background-color: rgb(155, 164, 143);
}
</style>

<script>
import position1 from "./position1";
import position2 from "./position2";
export default {
  components: {
    position1,
    position2,
  },
  data() {
    return {
      name: "xtabs",
      tab: null,
      items: [
        { tab: "Position 1", content: "position1" },
        { tab: "Position 2", content: "position2" },
      ],
    };
  },
};
</script>
