<template>
  <div>
    <v-main>
      <v-container class="black--text" id="home-container">
        <banner></banner>

        <!-- <v-card
          height="400"
          width="256"
          class="mx-auto"
          style="background-color: transparent"
        >
          <v-navigation-drawer permanent>            
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  Application
                </v-list-item-title>
                <v-list-item-subtitle> subtext </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense nav>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="alice">Home</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card> -->

        <!-- <v-toolbar dense flat app color="transparent">
          <v-btn flat color="transparent"
            ><span class="text-uppercase alice">home</span>
            </v-btn>
            <v-btn flat color="transparent"
            ><span class="text-uppercase alice">Recruit</span>
            </v-btn>
            <v-btn flat color="transparent"
            ><span class="text-uppercase alice">lateral</span>
            </v-btn>
            <v-btn flat color="transparent"
            ><span class="text-uppercase alice">Profesional</span>
            </v-btn>
            <v-btn flat color="transparent"
            ><span class="text-uppercase alice">Events</span>
            </v-btn>
            <v-btn flat color="transparent"
            ><span class="text-uppercase alice">About</span>
            </v-btn>
            <v-btn flat color="transparent"
            ><span class="text-uppercase alice">Contact</span>
            </v-btn>
        </v-toolbar> -->
        <!-- <v-row>
          <v-col cols="12" md="12" class="alice d-flex justify-space-between">
            <span>menu 1</span>
          </v-col>
        </v-row> -->

        <!-- SECTION 1 -->
        <v-row no-gutters class="section1">
          <v-col
            lg="4"
            md="4"
            sm="12"
            class="alice d-flex flex-column justify-start wrap-fix"
          >
            <h1 class="pb-8">
              This here is a tag line to encourage people to apply.
            </h1>
            <h2 class="pb-1 med-marg">
              This is a short list of things about the department.
            </h2>
            <h2 class="pt-0 pb-1 med-marg">Another bit of info.</h2>
            <h2 class="pt-0 pb-0 med-marg">And one more.</h2>
            <v-spacer></v-spacer>
          </v-col>

          <v-col
            cols="4"
            md="4"
            sm="12"
            class="alice d-flex flex-column justify-start wrap-fix hide-on-small hide-on-medium"
          >
            <h2 class="pb-1 red--text">
              This background picture is just a placeholder, you'll need to
              provide us with pictures.
            </h2>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

        <!-- SECTION2 -->
        <v-row no-gutters class="section2">
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="alice d-flex flex-column justify-start wrap-fix"
          >
            <h1 class="pb-8">Another tag line about jobs.</h1>
            <h3 class="pb-1">
              A paragraph about why you should join the RPD. Lorem ipsum dolor
              sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat.
            </h3>
          </v-col>
        </v-row>

        <!-- SECTION 3 -->
        <!--  position 1 SECTION3 -  -->
        <v-row no-gutters class="section3 d-flex">
          <v-col
            lg="3"
            sm="11"
            md="12"
            xs="12"
            class="alice d-flex flex-column wrap-fix s3col"
          >
            <h1 class="pb-0">RECRUIT</h1>
            <hr class="mb-1 mt-n3" />
            <hr class="mb-4" />
            <div class="picHolder1">
              <h2>A PICTURE GOES HERE</h2>
            </div>

            <v-btn
              to="/position1"
              color="accent"
              class="mt-n2 posInfo align-self-center"
              >position information</v-btn
            >

            <h3 class="pb-1">
              A short descriptive paragraph here about the recruit position.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>

            <router-view></router-view>
          </v-col>
          <v-spacer></v-spacer>
          <!-- </v-row> -->

          <!--  position 2 SECTION3 -  -->
          <!-- <v-row no-gutters class="section3"> -->
          <v-col
            lg="3"
            sm="11"
            md="12"
            xs="12"
            class="alice d-flex flex-column wrap-fix s3col"
          >
            <h1 class="pb-0">LATERAL</h1>
            <hr class="mb-1 mt-n3" />
            <hr class="mb-4" />
            <div class="picHolder1">
              <h2>A PICTURE GOES HERE</h2>
            </div>

            <v-btn
              to="/position2"
              color="accent"
              class="mt-n2 posInfo align-self-center"
              >position information</v-btn
            >

            <h3 class="pb-1">
              A short descriptive paragraph here about the LATERAL position.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>

            <router-view></router-view>
          </v-col>
          <v-spacer></v-spacer>
          <!-- </v-row> -->

          <!--  position 3SECTION3 -  -->
          <!-- <v-row no-gutters class="section3"> -->
          <v-col
            lg="3"
            sm="11"
            md="12"
            xs="12"
            class="alice d-flex flex-column wrap-fix s3col"
          >
            <h1 class="pb-0">PROFESSIONAL</h1>
            <hr class="mb-1 mt-n3" />
            <hr class="mb-4" />
            <div class="picHolder1">
              <h2>A PICTURE GOES HERE</h2>
            </div>

            <v-btn
              to="/position3"
              color="accent"
              class="mt-n2 posInfo align-self-center"
              >position information</v-btn
            >

            <h3 class="pb-1">
              A short descriptive paragraph here about the PROFESIONAL position.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>

            <router-view></router-view>
          </v-col>
        </v-row>

        <!-- SECTION 4 -->
        <v-row no-gutters class="section4">
          <v-col
            cols="12"
            sm="12"
            xs="12"
            class="alice d-flex flex-column justify-start wrap-fix"
          >
            <h1 class="pb-8">
              What does it take to be an RPD Officer? (or some other similar
              wording).
            </h1>
            <!-- BACKGROUND REQ BUTTON -->
            <v-row no-gutters class="whats-next">
              <v-col
                cols="12"
                md="12"
                sm="12"
                class="alice d-flex flex-column justify-space-between wrap-fix"
              >
                <v-btn
                  to="/whatsNext"
                  color="accent"
                  class="mt-n2 align-self-center"
                  >Background Requirements</v-btn
                >
                <router-view></router-view>
                <v-spacer></v-spacer>

                <!-- JOB QUAL BUTTON -->
                <v-btn
                  to="/whatsNext"
                  color="accent"
                  class="mt-n2 align-self-center"
                  >Job Qualifications</v-btn
                >
                <router-view></router-view>
                <v-spacer></v-spacer>

                <!-- APP PROC BUTTON -->
                <v-btn
                  to="/whatsNext"
                  color="accent"
                  class="mt-n2 align-self-center"
                  >Application Process</v-btn
                >
                <router-view></router-view>

                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";

/* 0 media q */

@media (min-width: 0px) {
  #home-container {
    width: 100%;
    height: auto;
  }

  .alice {
    color: aliceblue;
  }

  .section1 {
    background-image: url("../images/rpd-bldg_346.png");
    height: 370px;
  }
  .section4 {
    background-image: url("../images/small-place-holder-346.png");
    height: 425px;
  }

  .section2,
  .section3 {
    background-color: #344e6b;
  }

  .wrap-fix {
    flex-wrap: wrap;
  }

  /*   .v-navigation-drawer {
    background-color: transparent;
  } */

  .section1 span,
  .section1 h1,
  .section1 h2,
  .section2 h1,
  .section2 h2,
  .section2 h3,
  .section3 h1,
  .section3 h2,
  .section3 h3,
  .section4 h1,
  .section4 h2 {
    padding: 5%;
    text-shadow: 2px 2px 4px #000000;
  }

  .section1 h2,
  .section2 h2,
  .section4 h2 {
    font-weight: 300;
  }

  .section2 h3,
  .section3 h3 {
    font-family: math;
    font-weight: 300;
  }

  .section3 hr {
    border-color: #ccb159;
    background-color: #ccb159;
  }

  .section3 .spacer {
    width: 0;
    flex-grow: 0 !important;
  }

  .s3col {
    justify-content: flex-start;
  }

  .picHolder1 {
    height: 242px;
    background-color: darkgrey;
  }

  .posInfo {
    opacity: 0.9;
  }

  .whats-next .v-btn {
    width: 75%;
  }
}

/* 768 media q */
@media (min-width: 768px) {
  .section1 {
    background-image: none;
    justify-content: center;
    width: 55%;
    margin: auto;
    margin-top: 20%;
  }

  .med-marg {
    margin-left: -30%;
  }

  .section2 {
    margin-top: 20%;
  }

  .section3 {
    flex-direction: column;
  }

  .s3col {
    align-self: center;
  }

  .section4 {
    background-image: none;
  }

  .whats-next .v-btn {
    width: 33%;
  }
  /*   .section1 {
    background-color: transparent;
    background-image: url("../images/reno-hero_1920.png");
    background-size: cover;
  } */
}

/* 1025 media q */
@media (min-width: 1025px) {
  .wrap-fix {
    flex-wrap: nowrap;
  }

  .med-marg {
    margin-left: 0;
  }

  .section1 {
    background-image: none;
    justify-content: flex-start;
    width: 53%;
    margin-top: 12%;
    margin-left: 11%;
  }

  .section3 {
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .section3 .spacer {
    width: 50px;
  }

  .s3col {
    justify-content: space-between;
  }

  .whats-next {
    margin-top: 10%;
  }
}

/* 2048 media q */
@media (min-width: 2048px) {
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
import xtabs from "./xtabs.vue";
import banner from "./banner.vue";
import position1 from "./position1.vue";
import position2 from "./position2.vue";
import position3 from "./position3.vue";
import whatsNext from "./whatsNext.vue";

// import auth from "./auth.vue";
//import addedit_diag from "./addedit_diag.vue";
//  const addedit_diag = () => import('./addedit_diag.vue')

export default {
  name: "Home",
  components: {
    xtabs,
    banner,
    position1,
    position2,
    position3,
    whatsNext,
  },
  mixins: [bus_common],
  data() {
    // Status, Fname, Lnprivateame, DOB, DIVISION
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" },
      ],
      right: null,
      search: "",
    };
  },
  watch: {
    "$store.state.user": function () {
      //keep this
      let self = this;
      this.init();
    },
  },
  methods: {
    /* sendRequest(rowData) {
      console.log(rowData);
    }, */
    sendRequest() {
      alert("This button does nothing yet.");
    },
    message1() {
      alert("when clicked this ");
    },

    init(self) {
      this.show_loader(false);
    },
  },
  mounted() {
    var self = this;
    this.$nextTick(() => {
      this.init(self);
    });
  },
};
</script>
